<template>
  <b-form-input
    v-model="value"
    v-mask="'###############'"
    type="text"
    @input="validateAndEmitPhoneNumber"
  />
</template>

<script>
export default {
  name: 'SchemaFormPhoneField',
  props: {
    value: String,
    field: Object,
  },
  methods: {
    validateAndEmitPhoneNumber() {
      if (this.field.required || this.value) {
        const phoneLength = this.value.length
        const validNumber = (phoneLength >= 10 && phoneLength <= 15)

        this.field.isValid = validNumber
        this.field.validationError = validNumber ?
          null : 'Invalid Phone Number, must be ' +
          (phoneLength < 10 ? 'at least 10 digits' : 'less than 15 digits')
        if (validNumber) {
          this.$emit('input', this.value)
        }
      }
    },
  },
}
</script>
